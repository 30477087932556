import MoveTo from 'moveto';

// Function can accept HTML element or selector string
export const scrollToElement = (target, duration = 500, offset = 0, onScrollEndCallback) => {
  const isDOMElement = target instanceof Element || target instanceof HTMLDocument;
  const element = isDOMElement ? target : document.querySelector(target);
  const header = document.querySelector('.mn_pageHeader[data-can-sticky="true"]');

  const moveTo = new MoveTo({
    tolerance: (header ? header.offsetHeight : 0) + offset,
  });

  moveTo.move(element, {
    duration,
    callback: (target) => onScrollEndCallback?.(target),
  });
};
